<template>
<div>

  <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Edit Payroll Paid">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            
            <b-row>
              
              <b-col md="6" class="mb-2">
                <b-form-group
                label="Name"
                class="required"
                >
                    <b-form-input
                    placeholder="3 to 50 characters"
                    v-model="form.name"
                    />
                  </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Client" class="required" >
                  <!-- <b-form-select v-model="form.organization">
                    <b-form-select-option value="">Select Client</b-form-select-option>
                    <b-form-select-option :value="organization._id" v-for="(organization, ind) in organizations" :key="ind">{{organization.organization_name | capitalize}}</b-form-select-option>
                  </b-form-select> -->
                  <v-select
                    class="customFormUser multiselect_muliple_options"
                    v-model="form.organization"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    :options="organizations"
                    placeholder="Select"
                    :getOptionLabel="option => option.organization_name"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            

            
            
            <b-row class="mt-2">
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name: 'payroll-paid'})"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BBreadcrumb, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import vSelect from 'vue-select'


export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    BBreadcrumb,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,

      form :{
        name : '',
        id   : '',
        organization: []      
      },
      organizations: [],      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-payroll-paid'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name: 'payroll-paid'});
                });
                
            }
        });
      
    },

    getDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/payroll-paid-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;
                
                this.form.name         = data.name;
                this.form.organization = this.organizations.filter(org => data.organization.includes(org._id));
                
                this.form.id = data._id;
                return this.form;
            }
        });
    },

    getOrganizations(){
			return this.$store.dispatch(POST_API, {
				data:{},
				api: '/api/all-client-list'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
				} else {
					var data = this.$store.getters.getResults.data;
					this.organizations = data;
					return this.organizations;
				}
			});
		},

    breadCrumb(){
      var item = [{
        to:{name:'admin-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'DataBase',
      },{
        to:{name:'payroll-paid'},
        text: 'Payroll Paid',
      },{
        to:null,
        text: 'Edit Payroll Paid',
        active:true             
      }];
      return item;
    },    

  },
  mounted(){
    this.getOrganizations().then(()=> {
      this.getDetail();
    });
  }
}
</script>
